import React from "react";
import { noNeedAuth } from "../Hocs/NoNeedAuth";

function Confidentiality() {
  return (
    <div className="legal">
      <div>
        <h1>POLITIQUE DE CONFIDENTIALITÉ DU SITE : ECOMPLANE</h1>
        <p>
          Cette politique de confidentialité s'applique au site : ECOMPLANE. La
          présente politique de confidentialité a pour but d'exposer aux
          utilisateurs du site :
        </p>
        <ul>
          <li>
            La manière dont sont collectées et traitées leurs données à
            caractère personnel. Doivent être considérées comme données
            personnelles toutes les données étant susceptibles d'identifier un
            utilisateur. Il s'agit notamment du prénom et du nom, de l'âge, de
            l'adresse postale, l'adresse électronique, la localisation de
            l'utilisateur ou encore son adresse IP.
          </li>
          <li>
            Quels sont les droits des utilisateurs concernant ces données.
          </li>
          <li>
            Éventuellement, la politique du site en matière de Transferts
            Internationaux.
          </li>
          <li>En matière de propriété intellectuelle.</li>
        </ul>
        <p>
          Cette politique de confidentialité complète les mentions légales et
          les Conditions Générales d'Utilisation que les utilisateurs peuvent
          consulter à l'adresse ci-après.
        </p>
      </div>

      <div>
        <h2>
          ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT
          DE DONNÉES
        </h2>
        <p>
          Conformément aux dispositions de l'article 5 du Règlement européen
          2016/679, la collecte et le traitement des données des utilisateurs du
          site respectent les principes suivants : Le traitement et la collecte
          des données à caractère personnel sont nécessaires aux fins des
          intérêts légitimes et privés poursuivis par le responsable du
          traitement ou par un tiers.
        </p>
      </div>

      <div>
        <h2>ARTICLE 4 : INFORMATIONS COLLECTÉES</h2>
        <p>
          Lorsque vous vous inscrivez à nos formations en e-commerce, nous
          collectons des informations personnelles telles que votre nom, adresse
          électronique, adresse postale, numéro de téléphone, informations de
          paiement, ainsi que des informations sur votre expérience et vos
          intérêts en matière de commerce électronique.
        </p>
      </div>

      <div>
        <h2>ARTICLE 5 : UTILISATION DES INFORMATIONS</h2>
        <p>
          Les informations collectées sont utilisées pour traiter votre
          inscription aux formations, personnaliser votre expérience
          d'apprentissage, communiquer avec vous au sujet de nos services,
          améliorer nos produits et services, et vous fournir un support client.
          Chez ECOMPLANE, nous utilisons les informations personnelles que vous
          nous fournissez de manière responsable et transparente.
        </p>
        <p>Voici comment nous utilisons vos informations :</p>
        <ul>
          <li>
            <strong>Traitement des Commandes :</strong> Nous utilisons vos
            informations personnelles pour traiter vos inscriptions et vos
            paiements pour nos formations en e-commerce. Cela inclut la
            vérification de votre identité, la gestion de votre compte, et la
            communication avec vous concernant les détails de la formation.
          </li>
          <li>
            <strong>Communication avec les Utilisateurs :</strong> Nous
            utilisons vos informations de contact pour vous envoyer des
            communications liées à votre utilisation de notre plateforme, telles
            que des confirmations d'inscription, des rappels de cours, des mises
            à jour sur nos services, et des informations sur de nouvelles offres
            ou promotions.
          </li>
          <li>
            <strong>Amélioration de nos Produits et Services :</strong> Vos
            commentaires et suggestions sont précieux pour nous aider à
            améliorer continuellement nos produits et services.
          </li>
          <li>
            <strong>Support Client :</strong> Nous utilisons vos informations
            personnelles pour répondre à vos demandes d'assistance et vous
            fournir un support client de qualité.
          </li>
        </ul>
      </div>

      <div>
        <h2>ARTICLE 6 : PARTAGE D'INFORMATIONS</h2>
        <p>
          Nous ne partageons pas vos informations personnelles avec des tiers,
          sauf lorsque cela est nécessaire pour fournir nos services de
          formation (par exemple, avec des fournisseurs de plateformes
          d'apprentissage en ligne) ou lorsque requis par la loi.
        </p>
        <ul>
          <li>
            <strong>Prestataires de Services :</strong> Nous pouvons partager
            vos informations personnelles avec des prestataires de services
            tiers qui nous aident à fournir nos services de formation en
            e-commerce.
          </li>
          <li>
            <strong>Conformité Juridique :</strong> Nous pouvons être tenus de
            divulguer vos informations personnelles dans le cadre d'une
            procédure légale ou d'une demande des autorités compétentes.
          </li>
        </ul>
      </div>

      <div>
        <h2>ARTICLE 7 : PROTECTION DES DONNÉES</h2>
        <p>
          Nous mettons en place des mesures de sécurité appropriées pour
          protéger vos informations contre tout accès non autorisé, la perte, la
          divulgation ou la destruction.
        </p>
      </div>

      <div>
        <h2>ARTICLE 8 : COOKIES ET TECHNOLOGIES SIMILAIRES</h2>
        <p>
          Nous utilisons des cookies et d'autres technologies de suivi pour
          améliorer votre expérience d'apprentissage sur notre plateforme. Vous
          pouvez gérer vos préférences en matière de cookies via les paramètres
          de votre navigateur.
        </p>
      </div>

      <div>
        <h2>ARTICLE 9 : ACCÈS ET CONTRÔLE DES DONNÉES</h2>
        <p>
          Vous pouvez accéder à vos données personnelles, les mettre à jour ou
          les supprimer en vous connectant à votre compte ECOMPLANE.
        </p>
      </div>

      <div>
        <h2>ARTICLE 10 : CONSERVATION DES DONNÉES</h2>
        <p>
          Nous conservons vos informations aussi longtemps que nécessaire pour
          fournir nos services de formation et nous conformer à nos obligations
          légales.
        </p>
      </div>

      <div>
        <h2>ARTICLE 11 : PROPRIÉTÉ INTELLECTUELLE</h2>
        <p>
          Tous les contenus présents sur la plateforme ECOMPLANE, y compris mais
          sans s'y limiter, les textes, images, vidéos, graphiques, logos,
          marques déposées, et tout autre contenu protégé par des droits de
          propriété intellectuelle, sont la propriété exclusive de ECOMPLANE ou
          de ses concédants de licence.
        </p>
      </div>

      <div>
        <h2>ARTICLE 12 : TRANSFERTS INTERNATIONAUX</h2>
        <p>
          Vos informations peuvent être transférées et stockées dans des pays en
          dehors de votre pays de résidence. Nous prenons des mesures pour
          assurer un niveau de protection adéquat des données lors de ces
          transferts.
        </p>
      </div>

      <div>
        <h2>ARTICLE 13 : DROITS DES UTILISATEURS</h2>
        <p>
          Vous avez le droit d'accéder à vos données personnelles, de les
          rectifier, de les supprimer, de vous opposer à leur traitement, et
          d'exercer d'autres droits en vertu des lois sur la protection des
          données.
        </p>
      </div>

      <div>
        <h2>ARTICLE 14 : MISES À JOUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
        <p>
          Cette politique de confidentialité peut être mise à jour
          périodiquement pour refléter les changements dans nos pratiques de
          collecte et de gestion des données.
        </p>
      </div>

      <div>
        <h2>COORDONNÉES</h2>
        <p>
          Si vous avez des questions ou des préoccupations concernant notre
          politique de confidentialité ou la gestion de vos données
          personnelles, veuillez nous contacter à l'adresse suivante :
        </p>
      </div>
    </div>
  );
}

export default noNeedAuth(Confidentiality);
