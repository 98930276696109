"use client";
import useAuthStore from "../Stores/Auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Sidebar from "../Uikits/Sidebar";
import { AppLoader } from "../Uikits/Others";

export const needAuth = (WrappedComponent, specificRole = false) => {
  return (props) => {
    const initAuth = useAuthStore((state) => state.initAuth);

    const user = useAuthStore((state) => state.user);
    const userLoading = useAuthStore((state) => state.userLoading);
    const userToken = useAuthStore((state) => state.userToken);
    const userIsConnected = useAuthStore((state) => state.userIsConnected);
    const getUserConnected = useAuthStore((state) => state.getUserConnected);
    const logout = useAuthStore((state) => state.logout);
    const navigate = useNavigate();

    useEffect(() => {
      initAuth();
    }, []);
    useEffect(() => {
      if (userToken && userIsConnected) {
        getUserConnected(userToken);
      } else {
        // logout();
      }
    }, [userIsConnected, userToken]);

    if (userLoading) return <AppLoader />;
    else if (!userLoading && !user) {
      return navigate("/login");
    }

    return (
      <>
        <Sidebar />
        <main className="connectedMain">
          <WrappedComponent {...props} />
        </main>
      </>
    );
  };
};
