import React from "react";
import { allNewletterUrl, getAllNewletter } from "../Utils/Queries/newletter";
import { needAuth } from "../Hocs/NeedAuth";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import { AddNewletter } from "../Components/newletter/manage";
import { SectionLoader } from "../Uikits/Others";
import useCustomQuery from "../Hook/query";

function ManageNewletter() {
  const { isLoading, error, data, refetch } = useCustomQuery(allNewletterUrl);
  const showModal = useModalStore((state) => state.showModal);

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion Newletter</h1>
      <BorderButton
        text="Nouvelle newletter"
        onClick={() => showModal(<AddNewletter refetch={refetch} />)}
      />
      {data.data.length > 0 ? (
        <div className="flex f-wrap dm-dataList2">
          {data.data.map((item, i) => (
            <article key={"newletter nb" + i}>
              <p>
                <b>Sujet: </b> {item.subject}
              </p>
              <p>
                <b>Message: </b> <br />
                <div dangerouslySetInnerHTML={{ __html: item.message }} />
              </p>
            </article>
          ))}
        </div>
      ) : (
        <p>Aucune newletter</p>
      )}
    </div>
  );
}

export default needAuth(ManageNewletter);
