"use client";
import useModalStore from "../Stores/Modal";
import React, { useRef, useState } from "react";

function Modal() {
  const ref = useRef(null);

  const visible = useModalStore((state) => state.visible);
  const content = useModalStore((state) => state.content);
  const hideModal = useModalStore((state) => state.hideModal);

  if (!visible) return;
  return (
    <div className="modal">
      <div className="modalContent" ref={ref}>
        <section className="mc-header" onClick={hideModal}>
          <span>X</span>
        </section>
        <section className="mc-body">{content}</section>
      </div>
    </div>
  );
}

export function ConfirmationModal({
  title,
  operationAfterValidation,
  subtitle,
}) {
  const [operationLoading, setOperationLoading] = useState(false);
  const hideModal = useModalStore((state) => state.hideModal);

  const handleValidation = () => {
    setOperationLoading(true);
    operationAfterValidation(() => {
      setOperationLoading(false);
      hideModal();
    });
  };

  return (
    <div className="confirmationModal flex f-column">
      <h1>{title} </h1>
      {subtitle && <div className="textCenter">{subtitle}</div>}
      <section className="flex jc-c">
        <button onClick={hideModal} disabled={operationLoading}>
          Annuler
        </button>
        <button onClick={handleValidation} disabled={operationLoading}>
          {operationLoading ? "Chargement..." : "Oui"}
        </button>
      </section>
    </div>
  );
}

export default Modal;
