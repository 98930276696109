"use client";
import { Field, useField } from "formik";
import React, { useState } from "react";
import { FormikErrorMessage } from ".";

export function FormikSharedTextInput(props) {
  const { fieldType, label, name, ph, min, max } = props;
  return (
    <div className="formField">
      {label && <label htmlFor="">{label}</label>}
      <Field
        type={fieldType}
        name={name}
        placeholder={ph}
        min={min}
        max={max}
      />
      <FormikErrorMessage name={name} />
    </div>
  );
}

export function FormikTextArea(props) {
  const { fieldType, label, name, ph } = props;
  return (
    <div className="formField">
      {label && <label htmlFor="">{label}</label>}
      <Field
        type={"textarea"}
        component="textarea"
        name={name}
        placeholder={ph}
      />
      <FormikErrorMessage name={name} />
    </div>
  );
}

export function FormikMultipleText(props) {
  const { fieldType, label, name, ph, newDataBtn, newDataPh } = props;
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { value } = meta;
  const [newDataInput, setNDI] = useState(false);
  const [newData, setND] = useState("");

  function handleNewData() {
    const copy = [...value];
    copy.push(newData);
    setValue(copy);
    setNDI(false);
  }

  function deleteData(i) {
    const copy = [...value];
    copy.splice(i, 1);
    setValue(copy);
  }
  return (
    <div className="formField multipleField">
      <button type="button" onClick={() => setNDI((prev) => !prev)}>
        {newDataInput ? "Annuler" : newDataBtn}{" "}
      </button>
      {value.length > 0 && (
        <div className="flex f-wrap">
          {value.map((item, i) => (
            <p className="flex ai-c" key={"multiple text nb" + i}>
              {item}{" "}
              <span className="flex ai-c jc-c" onClick={() => deleteData(i)}>
                X
              </span>{" "}
            </p>
          ))}
        </div>
      )}
      {newDataInput && (
        <article>
          <input
            placeholder={newDataPh}
            onChange={(e) => setND(e.target.value)}
          />
          <button type="button" onClick={handleNewData}>
            Ajouter
          </button>
        </article>
      )}
      <FormikErrorMessage name={name} />
    </div>
  );
}
