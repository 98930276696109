import React, { useState } from "react";
import { NewsLetter } from "../Components/others";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { oneProductUrl } from "../Utils/Queries/products";
import { useParams } from "react-router-dom";
import { SectionLoader } from "../Uikits/Others";
import { MdiArrowTopRightThin } from "../Uikits/Icons";
import Slider from "react-slick";
import useCustomQuery from "../Hook/query";

function ProductDetails() {
  const { productId } = useParams();
  const { isLoading, error, data, refetch } = useCustomQuery(
    oneProductUrl + productId
  );
  const [currentImage, setCI] = useState(0);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (i) => setCI(i),
  };

  if (isLoading) return <SectionLoader />;
  return (
    <div className="ProductsInfo ">
      {data ? (
        <>
          <h1>Produit {data.data.productNumber}</h1>
          <div className="flex f-wrap jc-se">
            <div className="PI-img">
              <div className="grande-image flex">
                <img src={data.data.pictures[currentImage].url} alt="" />
              </div>
              <div className="petite-image">
                <Slider {...settings}>
                  {[
                    data.data.pictures[0],
                    data.data.pictures[1],
                    data.data.pictures[2],
                    data.data.pictures[3],
                  ].map((item, i) => (
                    <section key={"slider nb" + i} onClick={() => setCI(i)}>
                      {" "}
                      <img src={item.url} alt="" />
                    </section>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="PI-text ">
              <div className="PI-text-prix flex f-column  ">
                <p>
                  <span>Nom du produit :</span>
                  <b> {data.data.name} </b>
                </p>
                <p>
                  <span>Prix d’achat (Fournisseur) :</span>
                  <b> {data.data.supplierPrice} €</b>
                </p>
                <p>
                  <span>Prix de vente :</span>
                  <b> {data.data.price} €</b>
                </p>
              </div>
              <div>
                <p className="">
                  <span>Lien Fournisseur:</span>
                  <a href={data.data.supplierLink} target="_blank">
                    Voir <MdiArrowTopRightThin />
                  </a>
                </p>
                <p>
                  <span>Lien Concurent:</span>
                  <a href={data.data.competitorLink} target="_blank">
                    Voir <MdiArrowTopRightThin />
                  </a>
                </p>
                <p>
                  <span>Lien Pub Concurent:</span>
                  <a href={data.data.competitorPubLink} target="_blank">
                    Voir <MdiArrowTopRightThin />
                  </a>
                </p>
                <p>
                  <span>Marché potentiel :</span>
                  {data.data.markets.map((item, i) => (
                    <React.Fragment key={"marche nb" + i}>
                      <strong>{item}</strong>
                      {i < data.data.markets.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
            <div className="pi-desc flex f-column">
              <h2>Description:</h2>
              <p dangerouslySetInnerHTML={{ __html: data.data.description }} />
            </div>
          </div>
        </>
      ) : (
        <p>Aucun produit correspondant</p>
      )}
      <NewsLetter />
    </div>
  );
}

export default noNeedAuth(ProductDetails);
