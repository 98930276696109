import { create } from "zustand";
import axiosInstance from "../Utils/Axios";

const useAuthStore = create((set) => ({
  userIsConnected: false,
  userLoading: false,
  userToken: null,
  user: null,

  initAuth: () => {
    const token =
      typeof window !== "undefined" ? localStorage.getItem("token") : null;
    if (token) {
      set({ userIsConnected: true, userToken: token, userLoading: true });
      axiosInstance.defaults.headers.common["x-auth-token"] = token;
    }
  },

  login: (token) => {
    localStorage.setItem("token", token);
    axiosInstance.defaults.headers.common["x-auth-token"] = token;
    set((state) => ({ ...state, userIsConnected: true, userToken: token }));
  },
  getUserConnected: async () => {
    try {
      let user = await axiosInstance.get("/auth/user");
      set((state) => ({ ...state, user: user.data, userLoading: false }));
    } catch (error) {
      localStorage.removeItem("token");
      set((state) => ({ ...state, userLoading: false }));
    }
  },
  logout: (token) => {
    localStorage.removeItem("token");
    set((state) => ({
      userIsConnected: false,
      userToken: null,
      userLoading: false,
      user: null,
    }));
  },
}));

export default useAuthStore;
