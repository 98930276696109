"use client";
import { ErrorMessage, useField } from "formik";
import React from "react";
import { FormikErrorMessage } from ".";

export function FormikFileInput(props) {
  const { name, label, accept } = props;
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <div className="formField formFieldFile flex f-column">
      <label>{label}</label>

      <section>
        <input
          type="file"
          name={name}
          id={"fileInp-" + name}
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              setValue(e.target.files[0]);
            }
          }}
          accept={accept ? accept : ""}
        />
        <label
          htmlFor={"fileInp-" + name}
          className={value ? "fileSelected" : ""}
        >
          {!value && <span>Choisir un fichier</span>}
        </label>
        <article>
          {value && (
            <p>
              {value.name}
              <small onClick={() => setValue("")}>Supprimer</small>
            </p>
          )}
        </article>
      </section>
      <FormikErrorMessage name={name} />
    </div>
  );
}

export function FormikFilesInput(props) {
  const { name, label, accept } = props;
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  function deleteByIndex(i) {
    const copy = [...value];
    copy.splice(i, 1);
    setValue(copy);
  }
  return (
    <div className="formField formFieldFile flex f-column">
      <label>{label}</label>

      <section>
        <input
          type="file"
          name={name}
          id={"fileInp-" + name}
          onChange={(e) => {
            if (e.target.files && e.target.files) {
              setValue(Array.from(e.target.files));
            }
          }}
          accept={accept ? accept : ""}
          multiple
        />
        <label
          htmlFor={"fileInp-" + name}
          className={value ? "fileSelected" : ""}
        >
          {value.length == 0 && <span>Choisir le/les fichier</span>}
        </label>
        <article>
          {value.length > 0 &&
            value.map((item, i) => (
              <p key={" file in nb" + i}>
                <span>{item.name}</span>
                <small onClick={() => deleteByIndex(i)}>Supprimer</small>
              </p>
            ))}
        </article>
      </section>
      <FormikErrorMessage name={name} />
    </div>
  );
}
