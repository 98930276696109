import axiosInstance from "../Axios";

export const allLeadPageContentUrl = "/leadPage/all";
export const allLeadPageContentByTypeUrl = (pageType) =>
  "/leadPage/all?pageType=" + pageType;

export const allLeadPageTypeUrl = "/leadPage/type/all";

export async function getAllLeadPageType() {
  let res = await axiosInstance.get(allLeadPageTypeUrl);
  return res;
}

export async function getAllLeadPageContent() {
  let res = await axiosInstance.get(allLeadPageContentUrl);
  return res;
}
