import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import { FormikProvider, useFormik, Form } from "formik";
import FormFieldProvider from "../Uikits/form";
import { updateAccountFields } from "../Config/formFields/fieldArrays";
import { FormButton } from "../Uikits/Button";
import axiosInstance from "../Utils/Axios";
import { errorToast, successToast } from "../Utils/Toast";
import useAuthStore from "../Stores/Auth";
import dayjs from "dayjs";

function Dashboard() {
  const user = useAuthStore((state) => state.user);
  const formik = useFormik({
    initialValues: { email: user.email, password: "", name: user.name },
    onSubmit: handleSubmit,
  });
  const { isSubmitting, values, setSubmitting, isValid } = formik;

  function handleSubmit(formValues) {
    axiosInstance
      .put("/auth/update-user", formValues)
      .then((res) => successToast("Modification Enregistrer !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => setSubmitting(false));
  }
  return (
    <div className="dashboard">
      <section>
        <div className="fakeWatch">
          <p className="flex f-column">
            <b>{dayjs().format("D")} </b>
            <span>{dayjs().format("MMM")}</span>
          </p>
          <p className="flex">
            <b>{dayjs().format("HH")} h</b>:
            <span className="flex">{dayjs().format("mm")} min</span>
          </p>
        </div>
        <img src="/images/shoots/pp3.webp" alt="" />
      </section>
      <section>
        <h1>Bonjour {user.email} </h1>
        <p>Bienvenue sur votre espace adminisatrateur</p>
        <FormikProvider value={formik}>
          <Form>
            {updateAccountFields.map((item, i) => (
              <FormFieldProvider {...item} key={"up account nb" + i} />
            ))}
            <FormButton
              text="Enregistrer"
              isValid={isValid}
              isSubmitting={isSubmitting}
            />
          </Form>
        </FormikProvider>
      </section>
    </div>
  );
}

export default needAuth(Dashboard);
