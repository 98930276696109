import dayjs from "dayjs";

export const allTimes = ["Mois", "An"];
const timeOptions = [
  { label: "Mois", value: "Mois" },
  { label: "An", value: "An" },
];
// const gendersArray = Object.entries(gendersTranslations).map(([key, value]) => ({ label: value, value: key }));

export const nameField = {
  fieldType: "text",
  label: "Votre nom",
  ph: "Marie,Mario",
  name: "name",
};

export const emailField = {
  fieldType: "email",
  label: "Votre email",
  ph: "abc@gmail.com",
  name: "email",
};

export const subjectField = {
  fieldType: "text",
  label: "Le sujet du contact",
  ph: "Le Sujet",
  name: "subject",
};

export const messageField = {
  fieldType: "textarea",
  label: "Votre message",
  ph: "Message ici",
  name: "message",
};
export const passwordField = {
  fieldType: "password",
  label: "Mot de passe",
  ph: "MonMotdePasse@1234",
  name: "password",
};
export const passwordConfirmationField = {
  fieldType: "password",
  label: "Confirmation de mot de passe",
  ph: "MonMotdePasse@1234",
  name: "passwordConfirmation",
};

// export const availabilityType = {
//   fieldType: "select",
//   label: "Type de Disponibilite",
//   name: "availabilityType",
//   valueKey: "value",
//   labelKey: "label",
//   chooseLabelKey: "Choisir type",
//   options: [
//     { label: "Heure", value: "hour" },
//     { label: "Jour", value: "day" },
//   ],
// };

// export const availabilityTime = {
//   fieldType: "number",
//   label: "Valeur de Disponibilite",
//   ph: "Ex: 1,2,12",
//   name: "availabilityTime",
// };

export const availabilityDate = {
  fieldType: "date",
  label: "Type de Disponibilite",
  name: "availabilityDate",
};

export const availabilityTime = {
  fieldType: "time",
  label: "Valeur de Disponibilite",
  ph: "Ex: 1,2,12",
  name: "availabilityTime",
};

export const formationName = {
  fieldType: "text",
  label: "Titre de la formation",
  ph: "Ex: Standard,Basique",
  name: "name",
};

export const formationPrice = {
  fieldType: "number",
  label: "Prix de la formation",
  name: "price",
};

export const formationTime = {
  fieldType: "radio",
  label: "Duree de la formation",
  name: "time",
  options: timeOptions,
  valueKey: "value",
  labelKey: "label",
};

export const formationDetails = {
  fieldType: "multipleText",
  label: "Details de la formation",
  name: "details",
  newDataBtn: "Ajouter un nouveau detail",
  newDataPh: "Nouveau detail",
};

export const productMarkets = {
  fieldType: "multipleText",
  label: "marché pour se lancer",
  name: "markets",
  newDataBtn: "Ajouter un nouveau marché",
  newDataPh: "Nouveau marché",
};

export const productId = {
  fieldType: "text",
  label: "Id du produit",
  name: "productNumber",
};

// export const modelEthnicity = {
//     fieldType: 'radio', label: 'Origine du Model', name: 'ethnicity',
//     options: ethnicityArray, valueKey: 'value', labelKey: 'label'
// }

export const modelDesc = {
  fieldType: "text",
  label: "Une mini description",
  name: "description",
  ph: "Je suis atachiante",
};

export const appName = {
  fieldType: "select",
  label: "Pour l'application",
  name: "appName",
  valueKey: "value",
  labelKey: "label",
  chooseLabelKey: "Choisir application",
  options: [
    { label: "Ecomplane", value: "Ecomplane" },
    { label: "Addplane", value: "Addplane" },
  ],
};

export const autorName = {
  fieldType: "text",
  label: "Nom de lauteur",
  ph: "Ex: John Doe",
  name: "autorName",
};

export const autorText = {
  fieldType: "text",
  label: "Commentaire(texte)",
  ph: "Ex: Tres bonne formation",
  name: "text",
};

export const autorPicture = {
  fieldType: "file",
  label: "La photo de lauteur",
  name: "autorPicture",
  accept: "image/*",
};
export const autorAudioOrVideoField = {
  fieldType: "file",
  label: "Commentaire(Audio/Video)",
  name: "audioOrVideo",
  accept: "audio/*,video/*",
};

export const mediaField = {
  fieldType: "file",
  label: "Le fichier",
  name: "media",
  accept: "image/*,video/*",
};

export const productPictures = {
  fieldType: "files",
  label: "Les images du produits",
  name: "pictures",
  accept: "image/*,video/*",
  multiple: true,
};

export const productPicture1 = {
  fieldType: "file",
  label: "Image 1 du produits(principal) ",
  name: "picture1",
  accept: "image/*",
};
export const productPicture2 = {
  fieldType: "file",
  label: "Image 2 du produits",
  name: "picture2",
  accept: "image/*",
};
export const productPicture3 = {
  fieldType: "file",
  label: "Image 3 du produits",
  name: "picture3",
  accept: "image/*",
};
export const productPicture4 = {
  fieldType: "file",
  label: "Image 4 du produits",
  name: "picture4",
  accept: "image/*",
};

export const productName = {
  fieldType: "text",
  label: "Nom du produit",
  ph: "Ex: Perle rare",
  name: "name",
};

export const productDesc = {
  fieldType: "editor",
  label: "Description du produit",
  ph: "Ex: Description...",
  name: "description",
  additionalOptions: [],
};

export const productSupplierPrice = {
  fieldType: "number",
  label: "Prix du fournisseur",
  ph: "Ex: Perle rare",
  name: "supplierPrice",
};

export const productPrice = {
  fieldType: "number",
  label: "Prix",
  ph: "Ex: Perle rare",
  name: "price",
};

export const productSupplierLink = {
  fieldType: "text",
  label: "Lien fournisseur",
  ph: "Ex: https://www.youtube.com/...",
  name: "supplierLink",
};

export const productCompetitorLink = {
  fieldType: "text",
  label: "Lien competiteur",
  ph: "Ex: https://www.youtube.com...",
  name: "competitorLink",
};

export const productCompetitorPubLink = {
  fieldType: "text",
  label: "Lien Pub competiteur",
  ph: "Ex: https://www.youtube.com...",
  name: "competitorPubLink",
};

export const lpTypeLabel = {
  fieldType: "text",
  label: "Label de la page lead",
  ph: "Ex: Pays,Numero etc...",
  name: "label",
};

export const lpParagraph = {
  fieldType: "editor",
  label: "Paragraphe de la section",
  ph: "Ex: lorem ipsum.....",
  name: "paragraph",
  additionalOptions: [],
};

export const lpButtontext = {
  fieldType: "text",
  label: "Text du bouton",
  ph: "Ex: Cliquez moi.....",
  name: "buttonText",
};

export const lpButtonLink = {
  fieldType: "text",
  label: "Lien du bouton",
  ph: "Ex: https://google.com.....",
  name: "buttonLink",
};

export const lpVideo = {
  fieldType: "file",
  label: "Video de la section",
  name: "otherMedias",
  accept: "video/*",
};

export const lpPdfImg = {
  fieldType: "file",
  label: "Visuel du pdf",
  name: "otherMedias",
  accept: "image/*",
};

export const lpPdf = {
  fieldType: "file",
  label: "PDf de la section",
  name: "documents",
  accept: "application/pdf",
};

export const lpGallery = {
  fieldType: "files",
  label: "Gallerie d'images de la section",
  name: "otherMedias",
  accept: "image/*",
};

export const subject2Field = {
  fieldType: "text",
  label: "Sujet de la Newletter",
  ph: "Ex: Les pubs en ecommerce...",
  name: "subject",
};

export const messageEditorField = {
  fieldType: "editor",
  label: "Message",
  ph: ".....",
  name: "message",
  additionalOptions: [
    ["fontColor", "hiliteColor", "textStyle"],
    ["table", "link", "image"],
  ],
};

export const stripeLinkField = {
  fieldType: "text",
  label: "Lien stripe",
  ph: ".....",
  name: "stripeLink",
};

// productNumber
