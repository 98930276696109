import axiosInstance from "../Axios";
import { errorToast, successToast } from "../Toast";

export const allLeadUrl = "/lead/all?leadType=";
export const allLeadByTypeUrl = "/lead/all?leadType=";
export async function getAllLeads(type) {
  let res = await axiosInstance.get("/lead/all?leadType=" + type);
  return res;
}

export function addNewletterLead(data) {
  let res = axiosInstance
    .post("/lead/add", data)
    .then((res) => successToast("Mail ajouter"))
    .catch((res) => errorToast("Erreur survenue"))
    .finally((res) => null);
}
