import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import useModalStore from "../Stores/Modal";
import axiosInstance from "../Utils/Axios";
import { allLeadPageTypeUrl } from "../Utils/Queries/leadPage";
import { successToast } from "../Utils/Toast";

import { BackgroundButton, BorderButton } from "../Uikits/Button";
import { SectionLoader } from "../Uikits/Others";
import useCustomQuery from "../Hook/query";
import { AddorUpdateLPType } from "../Components/leadPageType/addOrUpdate";

function ManageLeadPageType() {
  const { isLoading, error, data, refetch } =
    useCustomQuery(allLeadPageTypeUrl);

  const showModal = useModalStore((state) => state.showModal);

  function deleteTypeAndContent(typeId) {
    axiosInstance
      .delete("/leadPage/type/delete/" + typeId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Section supprimer !");
      });
  }

  function duplicateTypeAndContent(typeId) {
    axiosInstance
      .post("/leadPage/type/duplicate/" + typeId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Section duppliquer !");
      });
  }

  if (isLoading) return <SectionLoader />;

  return (
    <div className="dataManager">
      <h1>Gestion des type de page Lead </h1>
      <div
        className="flex f-wrap jc-se"
        style={{
          gap: "10px",
        }}
      >
        <BorderButton
          text="Nouveau type de page lead"
          onClick={() => showModal(<AddorUpdateLPType refetch={refetch} />)}
        />
      </div>
      {data.data.length > 0 ? (
        <section className="dm-dataList2">
          {data.data.map((item, i) => (
            <article key={"formations nb " + i}>
              <h2>Page lead: {item.label} </h2>
              <div
                className="flex f-wrap"
                style={{ gap: "15px", marginTop: 25 }}
              >
                <BorderButton
                  text="Modifier le label de la page lead"
                  onClick={() =>
                    showModal(
                      <AddorUpdateLPType
                        refetch={refetch}
                        leadPageType={item}
                      />
                    )
                  }
                />
                <BorderButton
                  text="Dupliquer la page et le contenu"
                  onClick={() => duplicateTypeAndContent(item._id)}
                />
                <BorderButton
                  text="Editer le contenu de la page"
                  isLink={true}
                  link={
                    "/manage-leadPageContents/" + item._id + "/" + item.label
                  }
                />
                <BackgroundButton
                  text="Suprimer la page et son contenu"
                  onClick={() => deleteTypeAndContent(item._id)}
                />
              </div>
            </article>
          ))}
        </section>
      ) : (
        <p>Aucune page lead disponible</p>
      )}
    </div>
  );
}

export default needAuth(ManageLeadPageType);
