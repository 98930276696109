import React from "react";
import { SvgSpinners6DotsRotate, SvgSpinnersClock } from "./Icons";

export function AppLoader() {
  return (
    <div className="appLoader">
      <SvgSpinnersClock />
    </div>
  );
}

export function SectionLoader() {
  return (
    <div className="sectionLoader">
      <SvgSpinners6DotsRotate />
    </div>
  );
}
