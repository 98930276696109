"use client";
import { create } from "zustand";

const useModalStore = create((set) => ({
  visible: false,
  content: null,
  showModal: (content) => {
    set({ visible: true, content });
  },
  hideModal: () => {
    set({ visible: false, content: null });
  },
}));

export default useModalStore;
