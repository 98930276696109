import { Form, FormikProvider, useFormik } from "formik";
import {
  formationsAvailabilityFields,
  formationsFields,
} from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import {
  FormationAvailaibilityValidation,
  NewFormationValidation,
} from "../../Config/formFields/fieldsValidations";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import dayjs from "dayjs";

export function AddorUpdateFormationForm({ refetch, formation }) {
  const formik = useFormik({
    initialValues: {
      appName: formation?.appName ?? "",
      name: formation?.name ?? "",
      price: formation?.price ?? 100,
      time: formation?.time ?? "",
      details: formation?.details ?? [],
      stripeLink: formation?.stripeLink ?? "",
    },
    validateOnMount: true,
    validationSchema: NewFormationValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = formation
      ? axiosInstance.put("/formation/update/" + formation._id, formvalues)
      : axiosInstance.post("/formation/add", formvalues);

    request
      .then((res) => successToast("Formation ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {formationsFields.map((item, i) => (
          <FormFieldProvider {...item} key={"gros lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}

export function AddorUpdateFormationAvailabilityForm({ refetch, formation }) {
  const formik = useFormik({
    initialValues: {
      availabilityDate:
        dayjs(parseInt(formation.availableFor)).format("YYYY-MM-DD") ?? "",
      availabilityTime:
        dayjs(parseInt(formation.availableFor)).format("HH:mm") ?? "",
    },
    validateOnMount: true,
    validationSchema: FormationAvailaibilityValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const date = dayjs(
      formvalues.availabilityDate + " " + formvalues.availabilityTime
    ).format("YYYY-MM-DD HH:mm");
    const dateTimestamp = dayjs(date).valueOf();

    axiosInstance
      .put("/formation/update/availability/" + formation._id, {
        availability: dateTimestamp,
      })
      .then((res) => successToast("Timer ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {formationsAvailabilityFields.map((item, i) => (
          <FormFieldProvider {...item} key={"for avail lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
