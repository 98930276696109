import axiosInstance from "../Axios";

export const allFormationUrl = "/formation/all";
export const allFormationByAppUrl = "/formation/all?appName=";

export async function getAllFormations() {
  let res = await axiosInstance.get(allFormationUrl);
  return res;
}

export async function getAllFormationsByApp(appName) {
  let res = await axiosInstance.get(allFormationByAppUrl + appName);
  return res;
}
