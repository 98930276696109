import React from "react";
import Slider from "react-slick";
import { MdiFormatQuoteOpen } from "../../Uikits/Icons";
import { useQuery } from "@tanstack/react-query";
import { getAllReviews, getAllReviewsByApp } from "../../Utils/Queries/reviews";

const testimonials = [
  {
    name: "Jean Dupont",
    gender: "m",
    title: "Entrepreneur",
    content:
      "Grâce à cette formation, j'ai pu doubler mes ventes en seulement trois mois. L'équipe d'experts est toujours disponible pour aider et fournir des conseils précieux.",
  },
  {
    name: "Marie Dubois",
    gender: "f",
    title: "Propriétaire de boutique en ligne",
    content:
      "Les stratégies marketing proposées sont vraiment efficaces. J'ai pu toucher une nouvelle audience et mes ventes ont explosé !",
  },
  {
    name: "Laurent Martin",
    gender: "m",
    title: "Consultant",
    content:
      "L'accompagnement est exceptionnel, j'ai enfin pu automatiser mes processus et me concentrer sur d'autres aspects de mon business. Je recommande fortement !",
  },
  {
    name: "Sophie Lemaitre",
    gender: "f",
    title: "Auto-entrepreneuse",
    content:
      "Les conseils pour les publicités Facebook et TikTok ont totalement transformé ma manière de gérer mes campagnes. Je n'aurais jamais pensé obtenir de tels résultats si rapidement.",
  },
  {
    name: "Pierre Durand",
    gender: "m",
    title: "Freelance",
    content:
      "Le contenu de cette formation est clair, précis et directement applicable. J'ai atteint mes premiers 10 000 € de chiffre d'affaires beaucoup plus rapidement que prévu.",
  },
];

const testiSettings = {
  arrows: false,
  dots: true,
  customPaging: function (i) {
    return <span> {i} </span>;
  },
  adaptiveHeight: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 758,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

function HomeTestimonial() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allReviews"],
    queryFn: () => getAllReviewsByApp("Ecomplane"),
    initialData: [],
  });

  if (isLoading) return "...";
  if (!data.data) return;
  if (data.data && data.data.length == 0) return;
  return (
    <div className="homeTestimonial">
      <h2> Ce que disent nos élèves </h2>
      <section>
        <Slider {...testiSettings}>
          {data.data.map((item, i) => (
            <article key={"testi nb" + i} className="testimonialCard">
              <section className="flex">
                <span>
                  <MdiFormatQuoteOpen />
                </span>
                <p> {item.text}</p>
              </section>

              <div>
                <img src={item.autorPicture.url} alt="" />
                <div className="flex f-column">
                  <h4>{item.autorName} </h4>
                  {/* <span>{item.title} </span> */}
                </div>
              </div>
            </article>
          ))}
        </Slider>
      </section>
    </div>
  );
}

export default HomeTestimonial;
