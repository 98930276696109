import React from "react";
import {
  MdiAccountGroup,
  MdiBankCheck,
  MdiCartCheck,
  MdiCurrencyEur,
} from "../../Uikits/Icons";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ScrollAnimation from "react-animate-on-scroll";

const resultsStats = [
  {
    number: 400,
    text: "Eleve former",
    icon: <MdiAccountGroup />,
  },
  {
    number: 8000000,
    text: "De chriffre daffaire realiser",
    icon: <MdiCurrencyEur />,
  },
  {
    number: 150000,
    text: "Produits vendus",
    icon: <MdiCartCheck />,
  },
  {
    number: 5,
    text: "Marques Ecommerce",
    icon: <MdiBankCheck />,
  },
];

function HomeResultStats() {
  return (
    <div className="homeResultsStats flex f-wrap">
      <div className="hRSText flex f-column">
        <ScrollAnimation animateIn="bounceInLeft">
          <h2 className="hRST-title">
            Votre clé vers <br />
            <span>l'indépendance</span> <br />
            financière
          </h2>
          <p>
            Nous avons compilés les meilleurs de nos expériences, savoirs,{" "}
            <br />
            outils et construit une communauté robuste pour vous
          </p>
        </ScrollAnimation>
      </div>

      <section className="hRSNumber flex f-wrap">
        <ScrollAnimation animateIn="bounceInRight" className=" flex f-wrap">
          {resultsStats.map((item, i) => (
            // <ScrollAnimation animateIn="bounceInRight" delay={500 * (i + 1)}>
            <article className="flex ai-c" key={"result stat nb" + i}>
              <span>{item.icon} </span>
              <div className="flex f-column">
                <h3>
                  +
                  <CountUp end={item.number}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>{item.text} </p>
              </div>
            </article>
            // </ScrollAnimation>
          ))}
        </ScrollAnimation>
      </section>
    </div>
  );
}

export default HomeResultStats;
