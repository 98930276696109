import axios from "axios";

// baseURL: "http://localhost:5100/api",
// ? "https://aqmee.refi.bj/api"

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV && process.env.NODE_ENV == "production"
      ? "https://backend.ecomplane.com/api"
      : "http://localhost:5100/api",
  //   timeout: 10000,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
});

export default axiosInstance;
