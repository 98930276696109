import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
function HomeCoach() {
  return (
    <div className="homeCoach">
      <ScrollAnimation
        animateIn="bounceInLeft"
        className="flex f-column hc-text"
      >
        <h3>
          « Tant que vous n'aurez pas rendu l'inconscient conscient, il régira
          votre vie et vous l'appellerez le destin.»
        </h3>
        <p>
          Le plus bon moment c'est maintenant, l'ecommerce comme de nombreux
          business en lignes font chaque jour des centaines de millionnaires qui
          étaient comme vous auparavant, mais ont décidé de prendre leur vie en
          main et de passer à l'action.
        </p>
        <hr />
        <section>
          <h4>Marie-Pierrot CAKPO</h4>
          <p>Coach Formateur Expert en E-Commerce</p>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceIn" className="hc-img">
        <img src="/images/shoots/pp4.webp" alt="" />
      </ScrollAnimation>
    </div>
  );
}

export default HomeCoach;
