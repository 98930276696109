import { Form, FormikProvider, useFormik } from "formik";
import { reviewsFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import {
  ReviewsValidation,
  UpReviewValidation,
} from "../../Config/formFields/fieldsValidations";

export function AddorUpdateReviewForm({ refetch, review }) {
  const formik = useFormik({
    initialValues: {
      appName: review?.appName ?? "",
      autorName: review?.autorName ?? "",
      text: review?.text ?? "",
      autorPicture: "",
      audioOrVideo: "",
    },
    validateOnMount: true,
    validationSchema: review ? UpReviewValidation : ReviewsValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formData = new FormData();
    formData.append("autorName", formvalues.autorName);
    formData.append("text", formvalues.text);
    formData.append("autorPicture", formvalues.autorPicture);
    formData.append("audioOrVideo", formvalues.audioOrVideo);
    formData.append("appName", formvalues.appName);
    let request = review
      ? axiosInstance.put("/review/update/" + review._id, formData)
      : axiosInstance.post("/review/add", formData);

    request
      .then((res) => successToast("Avis client ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {reviewsFields.map((item, i) => (
          <FormFieldProvider {...item} key={"gros lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
