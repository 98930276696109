import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const proofSlideSettings = {
  arrows: false,
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 758,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function HomeCustomerProof() {
  return (
    <div className="homeCustomerProof">
      <h2>
        Quelques résultats de <br /> nos élèves
      </h2>
      <div>
        <Slider {...proofSlideSettings}>
          {[0, 0, 0, 0, 0].map((item, i) => (
            <img
              key={"customer proof nb" + i}
              src={"/images/proofs/" + (i + 1) + ".webp"}
              alt={"customer proof nb" + i}
            />
          ))}
        </Slider>
      </div>
      <Link to="/testimonials">Voir plus</Link>
    </div>
  );
}

export default HomeCustomerProof;
