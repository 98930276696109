import {
  appName,
  autorAudioOrVideoField,
  autorName,
  autorPicture,
  autorText,
  availabilityDate,
  availabilityTime,
  availabilityType,
  emailField,
  formationDetails,
  formationName,
  formationPrice,
  formationTime,
  lpButtonLink,
  lpButtontext,
  lpGallery,
  lpParagraph,
  lpPdf,
  lpPdfImg,
  lpTypeLabel,
  lpVideo,
  messageEditorField,
  messageField,
  nameField,
  passwordField,
  productCompetitorLink,
  productCompetitorPubLink,
  productDesc,
  productId,
  productMarkets,
  productName,
  productPictures,
  productPrice,
  productSupplierLink,
  productSupplierPrice,
  stripeLinkField,
  subject2Field,
  subjectField,
} from "./fields";

export const loginFields = [emailField, passwordField];

export const giveEmailFields = [emailField];

export const contactFields = [
  nameField,
  emailField,
  subjectField,
  messageField,
];

export const updateAccountFields = [nameField, emailField, passwordField];

export const formationsFields = [
  appName,
  formationName,
  formationPrice,
  formationTime,
  formationDetails,
  stripeLinkField,
];

export const formationsAvailabilityFields = [
  availabilityDate,
  availabilityTime,
];

export const reviewsFields = [
  appName,
  autorName,
  autorText,
  autorPicture,
  autorAudioOrVideoField,
];

export const productsFields = [
  productName,
  productPrice,
  productDesc,
  productPictures,
  productCompetitorLink,
  productCompetitorPubLink,
  productSupplierPrice,
  productSupplierLink,
  productMarkets,
  productId,
];

export const upProductsFields = [
  productName,
  productPrice,
  productDesc,
  productCompetitorLink,
  productCompetitorPubLink,
  productSupplierPrice,
  productSupplierLink,
  productMarkets,
  productId,
];

export const addLPTypeFields = [lpTypeLabel];

export const addLPParagraphFields = [lpParagraph];

export const addLPButtonFields = [lpButtontext, lpButtonLink];

export const addLPVideoFields = [lpVideo];

export const addLPPdfFields = [lpPdfImg, lpPdf];

export const addLPVGalleryFields = [lpGallery];

export const addNewletterMessageFields = [subject2Field, messageEditorField];
