import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import useModalStore from "../Stores/Modal";
import axiosInstance from "../Utils/Axios";
import { allProductsUrl } from "../Utils/Queries/products";
import { AddorUpdateProductForm } from "../Components/products/manage";
import { successToast } from "../Utils/Toast";
import { BorderButton } from "../Uikits/Button";
import { SectionLoader } from "../Uikits/Others";
import useCustomQuery from "../Hook/query";

function ManageProduct() {
  const { isLoading, error, data, refetch } = useCustomQuery(allProductsUrl);
  const showModal = useModalStore((state) => state.showModal);

  function deleteFormation(formationId) {
    axiosInstance
      .delete("/product/delete/" + formationId)
      .then((res) => null)
      .catch((err) => null)
      .finally(() => {
        refetch();
        successToast("Produit supprimer !");
      });
  }

  function updateImg(e, index, productId) {
    const formData = new FormData();
    formData.append("productPictures", e.target.files[0]);
    formData.append("fileIndex", index);
    axiosInstance
      .put("/product/update-file/" + productId, formData)
      .then((res) => null)
      .catch((err) => null)
      .finally(() => {
        refetch();
        successToast("Image mis a jour !");
      });
  }
  if (isLoading) return <SectionLoader />;

  return (
    <div className="dataManager">
      <h1>Gestions des Produits</h1>
      <BorderButton
        text="Nouveau produit"
        onClick={() => showModal(<AddorUpdateProductForm refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"formations nb " + i}>
              <section className="multipleImgs flex f-wrap">
                {item.pictures.map((img, l) => (
                  <>
                    <label htmlFor={i + "file nb" + l}>
                      <img src={img.url} key={"img nb" + l} alt="" />
                      <input
                        type="file"
                        id={i + "file nb" + l}
                        onChange={(e) => updateImg(e, l, item._id)}
                      />
                      <span>Modifier</span>
                    </label>
                  </>
                ))}
              </section>
              <p>
                <strong>Nom du produit: </strong>
                {item.name}
              </p>
              <p>
                <strong>Prix du produit: </strong>
                {item.price}
              </p>
              {/* <p>
                <strong>Description du produit: </strong>
                {item.description}
              </p> */}
              <p>
                <strong>Prix fournisseur: </strong>
                {item.supplierPrice}
              </p>
              {/* <p>
                <strong>Lien fournisseur: </strong>
                {item.supplierLink}
              </p>
              <p>
                <strong>Lien Concurrent: </strong>
                {item.competitorLink}
              </p>
              <p>
                <strong>Lien Pub Concurrent: </strong>
                {item.competitorPubLink}
              </p> */}

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AddorUpdateProductForm
                        product={item}
                        refetch={refetch}
                      />
                    )
                  }
                >
                  Modifier
                </button>
                <button
                  className="deleteBtn"
                  onClick={() => deleteFormation(item._id)}
                >
                  Supprimer
                </button>
              </div>
            </article>
          ))
        ) : (
          <p>Aucun avis disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageProduct);
