export function FormButton(props) {
  const { text, isValid, isSubmitting } = props;
  return (
    <div className="formButton">
      <button
        className="btn"
        disabled={isValid ? (isSubmitting ? true : false) : true}
        type="submit"
      >
        {isSubmitting ? "...." : text}
      </button>
    </div>
  );
}

export function BackgroundButton(props) {
  const { text, isLink, link, ...rest } = props;

  if (isLink) {
    return (
      <a className="btn backgroundButton" href={link} {...rest}>
        {text}{" "}
      </a>
    );
  }
  return (
    <button className="btn backgroundButton" {...rest}>
      {text}{" "}
    </button>
  );
}

export function BorderButton(props) {
  const { text, isLink, link, ...rest } = props;

  if (isLink) {
    return (
      <a className="btn borderButton" href={link} {...rest}>
        {text}{" "}
      </a>
    );
  }
  return (
    <button className="btn borderButton" {...rest}>
      {text}{" "}
    </button>
  );
}
