import axiosInstance from "../Axios";

export const allreviewsUrl = "/review/all";
export const allSpeAppReviewsUrl = "/review/all?appName=";

export async function getAllReviews() {
  let res = await axiosInstance.get("/review/all?appName=Ecomplane");
  return res;
}

export async function getAllReviewsByApp(appName) {
  let res = await axiosInstance.get(allSpeAppReviewsUrl + appName);
  return res;
}
