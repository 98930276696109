import React, { useEffect, useState } from "react";
import WavesurferPlayer from "@wavesurfer/react";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { MdiPause, MdiPlay } from "../Uikits/Icons";
import { useQuery } from "@tanstack/react-query";
import { getAllReviews } from "../Utils/Queries/reviews";
import {
  AudioTestimonial,
  TextTestimonial,
  VideoTestimonial,
} from "../Components/testimonial";
import { SectionLoader } from "../Uikits/Others";

function CliendNameAndPic() {
  return (
    <article className="clientNameAndPic">
      {" "}
      <img
        src="https://aqmee.refi.bj/Storage/products/productPictures-1728432400891-674792949.jpg"
        alt=""
      />
      <b>NOm et Prenom</b>
    </article>
  );
}
function AllTestimonials() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allReviews"],
    queryFn: getAllReviews,
  });

  if (isLoading) return <SectionLoader />;
  return (
    <div className="allTestimonials flex f-column">
      <h1>Témoignages</h1>
      <TextTestimonial testimonials={data.data} />

      <AudioTestimonial testimonials={data.data} />

      <VideoTestimonial testimonials={data.data} />
    </div>
  );
}

export default noNeedAuth(AllTestimonials);
