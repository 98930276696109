import { Form } from "formik";
import React from "react";
import { useFormik } from "formik";
import { loginFields } from "../Config/formFields/fieldArrays";
import FormFieldProvider from "../Uikits/form";
import { FormikProvider } from "formik";
import { FormButton } from "../Uikits/Button";
import useAuthStore from "../Stores/Auth";
import axiosInstance from "../Utils/Axios";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { errorToast } from "../Utils/Toast";

function Login() {
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: handleSubmit,
  });
  const { isSubmitting, setSubmitting, isValid } = formik;
  const login = useAuthStore((state) => state.login);

  function handleSubmit(formValues) {
    axiosInstance
      .post("/auth/login", formValues)
      .then((res) => login(res.data.token))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => setSubmitting(false));
  }
  return (
    <div className="login flex ai-c jc-c">
      <div>
        <h1>Connexion Administration</h1>
        <FormikProvider value={formik}>
          <Form action="">
            {loginFields.map((item, i) => (
              <FormFieldProvider {...item} key={"logien field nb" + i} />
            ))}
            <FormButton
              text="Soumettre"
              isValid={isValid}
              isSubmitting={isSubmitting}
            />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}

export default noNeedAuth(Login);
