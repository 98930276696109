import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import useAuthStore from "../Stores/Auth";
import { MdiFormatListCheckbox } from "./Icons";

// leadPageType
// leadPageContents
function Sidebar() {
  const [visibleOnMobile, setVOM] = useState(false);
  const logout = useAuthStore((state) => state.logout);
  const links = [
    { to: "/dashboard", label: "Accueil" },
    { to: "/manage-formations", label: "Formations" },
    { to: "/manage-reviews", label: "Témoignage" },
    { to: "/manage-products", label: "Produits" },
    { to: "/manage-leadPageType", label: "Page lead" },
    { to: "/manage-lead/product", label: "Emails(produits)" },
    { to: "/manage-lead/newletter", label: "Emails(Newletter)" },
    { to: "/manage-newletter", label: "Gestion Newletter" },
  ];
  return (
    <aside
      className={"sidebar " + (visibleOnMobile && "sidebarVisibleOnMobile")}
    >
      <section className="sidebar-logo">
        <img src="/images/logoWhite.png" alt="" />
      </section>
      <section className="sidebar-links">
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.to}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            {link.label}
          </NavLink>
        ))}
      </section>

      <button onClick={logout}>Se deconnecter</button>

      <span
        className="mobileSidebarToggler"
        onClick={() => setVOM((prev) => !prev)}
      >
        <MdiFormatListCheckbox />
      </span>
    </aside>
  );
}

export default Sidebar;
