import React, { useState } from "react";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { NewsLetter } from "../Components/others";

const Faq = () => {
  const [openPanels, setOpenPanels] = useState({});
  const [changecolor, setChangecolor] = useState(false);
  const toggleColor = (changecolor) => {
    setChangecolor((prev) => !prev);
  };
  const togglePanel = (index) => {
    setOpenPanels((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const faqEcomplane = [
    {
      question: "1- Qu'est-ce qu'ECOMPLANE ?",
      answer:
        "ECOMPLANE est une plateforme de formation en ligne dédiée à vous guider vers la réussite dans le domaine du e-commerce. Que vous soyez débutant ou que vous ayez déjà une boutique en ligne, nos formations sont conçues pour vous donner des stratégies concrètes, des outils pratiques et des méthodes éprouvées pour booster vos ventes et développer votre business durablement.",
    },
    {
      question: "2- À qui s'adressent nos formations ?",
      answer:
        "Nos formations s’adressent à tous ceux qui souhaitent lancer ou développer un business en ligne, que vous soyez : Débutant en e-commerce sans expérience préalable. Entrepreneur cherchant à passer à l’étape suivante. Professionnel du marketing ou gestionnaire de boutique en ligne à la recherche d’outils pour maximiser la rentabilité de votre activité.",
    },
    {
      question: "3- Que vais-je apprendre dans vos formations ?",
      answer:
        "Chaque module de formation chez ECOMPLANE est conçu pour vous donner des compétences spécifiques et actionnables. Vous apprendrez à : Créer et configurer votre boutique en ligne de A à Z. Trouver des produits gagnants grâce à des techniques avancées de recherche de produits. Mettre en place des stratégies de marketing digital qui génèrent du trafic qualifié. Optimiser vos ventes grâce aux meilleures techniques de conversion. Automatiser et gérer votre e-commerce pour gagner en efficacité et en rentabilité.",
    },
    {
      question:
        "4- Combien de temps me faudra-t-il pour réussir en e-commerce ?",
      answer:
        "Les résultats varient d'une personne à l'autre en fonction de votre implication et de votre situation de départ. Cependant, nos formations sont structurées pour vous faire progresser rapidement. Certains de nos étudiants constatent une croissance notable de leurs ventes en quelques semaines après la mise en pratique des enseignements.",
    },
    {
      question: "5- Les formations sont-elles adaptées aux débutants ?",
      answer:
        "Absolument ! Nos formations sont conçues pour être accessibles à tous, même si vous n’avez aucune connaissance préalable en e-commerce ou en marketing digital. Nous vous guidons étape par étape avec des vidéos claires, des tutoriels détaillés et des exercices pratiques pour appliquer immédiatement ce que vous apprenez.",
    },
    {
      question:
        "6- Qu'est-ce qui rend ECOMPLANE différent des autres formations en ligne ?",
      answer:
        "Chez ECOMPLANE, nous nous concentrons sur la praticité et les résultats concrets. Voici ce qui nous différencie : Contenu 100 % actionnable : chaque leçon est conçue pour que vous puissiez appliquer directement ce que vous apprenez. Accompagnement personnalisé : vous ne serez jamais seul, notre équipe est là pour répondre à vos questions et vous accompagner dans votre parcours. Mises à jour continues : le monde de l'e-commerce évolue rapidement, c'est pourquoi nous mettons à jour régulièrement nos formations pour vous donner les dernières stratégies à jour. Communauté d'entrepreneurs : rejoignez une communauté d’apprenants comme vous, échangez des idées, trouvez du soutien et partagez vos succès.",
    },
    {
      question: "7- Quelle est la durée des formations ?",
      answer:
        "La durée des formations varie selon les programmes. Nos modules sont accessibles à votre rythme, vous pouvez donc avancer à votre convenance, que vous disposiez de quelques heures par semaine ou que vous souhaitiez y consacrer du temps chaque jour. Vous aurez également un accès à vie au contenu, ce qui vous permet de revenir sur les modules à tout moment.",
    },
    {
      question: "8- Proposez-vous des garanties de résultats ?",
      answer:
        "Nous croyons fermement en la qualité de nos formations et en leur capacité à transformer votre business en ligne. C'est pourquoi nous offrons une garantie satisfaction : si après avoir suivi l’ensemble de la formation vous ne voyez aucune amélioration dans votre activité, vous pouvez nous contacter pour un remboursement selon nos conditions. Votre réussite est notre priorité.",
    },
    {
      question: "9- Quels sont les moyens de paiement acceptés ?",
      answer:
        "Nous acceptons les paiements via carte de crédit, PayPal et d'autres méthodes de paiement sécurisées. Nous offrons également la possibilité de régler en plusieurs fois pour vous permettre d’investir sereinement dans votre formation.",
    },
    {
      question: "10- Comment puis-je commencer ?",
      answer:
        "C'est simple ! Cliquez sur le bouton 'S'inscrire' pour accéder à nos formations. Créez votre compte, choisissez le programme qui vous convient le mieux et commencez dès aujourd'hui à bâtir votre empire en ligne avec ECOMPLANE.",
    },
    {
      question:
        "11- Est-ce que le E-Commerce fonctionne toujours de nos jours ?",
      answer:
        "Absolument ! Le e-commerce n'a jamais été aussi florissant qu'aujourd'hui. Avec une croissance constante et des opportunités toujours plus grandes, il est temps de tirer parti de cette dynamique pour lancer ou développer votre propre boutique en ligne.",
    },
    {
      question: "12- Sommes-nous accompagnés durant la formation ?",
      answer:
        "Oui, tout à fait. En rejoignant notre formation, vous bénéficiez d'un accompagnement personnalisé avec un coach dédié. Nous vous guidons pas à pas, afin que vous ne soyez jamais seul dans votre parcours d’apprentissage.",
    },
    {
      question: "13- Abordez-vous les aspects administratifs et fiscaux ?",
      answer:
        "Bien sûr ! Nous couvrons en détail les aspects administratifs et fiscaux indispensables à la gestion d'un business en ligne, afin que vous soyez pleinement préparé à chaque étape, du lancement à la croissance de votre activité.",
    },
    {
      question:
        "14- Quel est le budget recommandé pour bien commencer en e-commerce ?",
      answer:
        "Le budget dépend de plusieurs facteurs, tels que votre niche, votre stratégie marketing et votre modèle d'affaires. Lors de la formation, nous vous aidons à établir un budget optimal en fonction de vos objectifs et de votre situation personnelle.",
    },
    {
      question:
        "15- Puis-je suivre la formation à mon rythme, en parallèle de mon travail ou de mes études ?",
      answer:
        "Oui, notre formation est conçue pour s’adapter à votre emploi du temps. Vous avez la liberté de progresser à votre propre rythme, que vous soyez un étudiant, un salarié ou un entrepreneur en activité.",
    },
    {
      question:
        "16- Ai-je besoin de compétences préalables en informatique pour me lancer ?",
      answer:
        "Pas du tout. Notre formation est accessible à tous, même à ceux sans aucune expérience en informatique ou en e-commerce. Nous avons conçu le programme de manière à ce que vous puissiez maîtriser les outils nécessaires et lancer votre business en ligne avec succès, quelle que soit votre expertise technique.",
    },
    {
      question:
        "17- J’ai peur de me lancer à cause de l'échec. Que dois-je faire ?",
      answer:
        "La peur de l'échec est naturelle, mais il est important de la voir comme une opportunité d'apprentissage. Chaque entrepreneur est confronté à des défis, mais ce sont ces expériences qui permettent de s'améliorer. Notre formation vous aide à transformer vos craintes en confiance, en vous fournissant les connaissances et les outils pour surmonter les obstacles et réussir.",
    },
    {
      question: "18- Pouvez-vous garantir que je vais obtenir des résultats ?",
      answer:
        "Nous ne pouvons pas garantir de résultats, car votre succès dépendra de votre engagement, de votre travail et de votre capacité à appliquer les stratégies enseignées. Cependant, nous vous fournissons toutes les ressources, les stratégies et le soutien nécessaires pour maximiser vos chances de réussite.",
    },
    {
      question:
        "19- La formation est-elle adaptée aux personnes déjà expérimentées en e-commerce ?",
      answer:
        "Oui, absolument. Nos programmes couvrent aussi bien les bases du e-commerce que les stratégies avancées. Si vous avez déjà de l’expérience, vous découvrirez les dernières tendances et techniques pour optimiser vos performances et continuer à développer votre activité.",
    },
    {
      question:
        "20- Je n’ai pas encore d'idée de produit. Puis-je tout de même me lancer ?",
      answer:
        "Bien sûr ! Notre formation inclut des méthodes éprouvées pour identifier des produits gagnants et rentables. Vous apprendrez à analyser le marché, à repérer les tendances et à choisir les produits avec le meilleur potentiel de succès.",
    },
    {
      question:
        "21- Comment se déroule la formation ? Est-elle en présentiel ?",
      answer:
        "Non, la formation est entièrement en ligne. Vous pouvez y accéder où que vous soyez, à tout moment. Tous les contenus sont disponibles sur notre plateforme, vous permettant de progresser à votre rythme.",
    },
    {
      question:
        "22- La formation peut-elle être appliquée aux marchés non européens ?",
      answer:
        "Oui, les principes et stratégies enseignés sont applicables à tous les marchés internationaux. Que vous souhaitiez vendre en Europe, aux États-Unis ou ailleurs, vous apprendrez comment adapter vos méthodes pour réussir sur le marché de votre choix.",
    },
  ];

  return (
    <div className="faq">
      <h1>FAQ</h1>
      <p>
        Des réponses claires et précises à toutes les questions que vous <br />
        pourriez avoir sur nos services
      </p>
      <div className="faq-text">
        {faqEcomplane.map((question, index) => (
          <div key={"faq" + index}>
            <button
              className={`faq-button ${openPanels[index] ? "rotate" : ""}`}
              onClick={() => togglePanel(index)}
              aria-expanded={openPanels[index] ? "true" : "false"}
              aria-controls={`panel${index}`}
            >
              <span className={` ${openPanels[index] ? "colorchange" : ""}`}>
                {question.question}
              </span>
              <svg
                className="faq-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </button>
            <div
              id={`panel${index}`}
              className={`faq-text1 ${openPanels[index] ? "open" : ""}`}
            >
              <span>{question.answer}</span>
            </div>
          </div>
        ))}
      </div>

      <NewsLetter />
    </div>
  );
};

export default noNeedAuth(Faq);
