import { Form, FormikProvider, useFormik } from "formik";
import { addLPParagraphFields } from "../../Config/formFields/fieldArrays";
import FormFieldProvider from "../../Uikits/form";
import { FormButton } from "../../Uikits/Button";
import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import { LeadPageParagraphValidation } from "../../Config/formFields/fieldsValidations";

export function AddorUpdateLPParagraph({ refetch, leadPageTypeId, section }) {
  const formik = useFormik({
    initialValues: {
      paragraph: section?.paragraph ?? "",
      leadPageType: leadPageTypeId,
    },
    validateOnMount: true,
    validationSchema: LeadPageParagraphValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    let request = section
      ? axiosInstance.put(
          "/leadPage/update-paragraph/" + section._id,
          formvalues
        )
      : axiosInstance.post("/leadPage/add-paragraph", formvalues);

    request
      .then((res) => successToast("Section ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        setSubmitting(false);
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {(section ? addLPParagraphFields : addLPParagraphFields).map(
          (item, i) => (
            <FormFieldProvider {...item} key={"gros lol" + i} />
          )
        )}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}
