import React from "react";
import { MdiGmail, MdiMapMarker, MdiPhoneClassic } from "../Uikits/Icons";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { Form, FormikProvider, useFormik } from "formik";
import { contactFields } from "../Config/formFields/fieldArrays";
import FormFieldProvider from "../Uikits/form";
import { FormButton } from "../Uikits/Button";
import { ContactValidation } from "../Config/formFields/fieldsValidations";

function Contact() {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
    },
    validationSchema: ContactValidation,
    validateOnMount: true,
    onSubmit: submitContact,
  });
  const { isValid, isSubmitting, setSubmitting } = formik;

  function submitContact(formvalues) {
    const { name, email, subject, message } = formvalues;
    const body = encodeURIComponent(
      `Nom: ${name}\nEmail: ${email}\nMessage: ${message}`
    );
    const mailtoLink = `mailto:example@example.com?subject=${encodeURIComponent(
      subject
    )}&body=${body}`;

    // Ouvre le lien mailto
    window.location.href = mailtoLink;
    setSubmitting(false);
  }
  return (
    <div className="contact">
      <h1 className="titre-contact"> Contactez-Nous</h1>
      <div className="formulaire">
        <div className="formulaire-input">
          <FormikProvider
            value={formik}
            isValid={isValid}
            isSubmitting={isSubmitting}
          >
            <Form>
              {contactFields.map((item, i) => (
                <FormFieldProvider {...item} key={"field nb " + i} />
              ))}
              <FormButton
                text="Envoyer"
                isValid={isValid}
                isSubmitting={isSubmitting}
              />
            </Form>
          </FormikProvider>
        </div>
        <div className="formulaire-img">
          <img src="/images/contact.svg" alt="" />
          <div className="f-img-bottom">
            <div className="">
              <b>Email</b>
              <p>
                <MdiGmail />
                info@ecomplane.com
              </p>
            </div>

            <div className="">
              <b>Telephone</b>
              <p>
                <MdiPhoneClassic />
                +33 632088663
              </p>
            </div>
            <div className="">
              <b>Localisation</b>
              <p>
                <MdiMapMarker />
                France
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default noNeedAuth(Contact);
