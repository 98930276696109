"use client";
import useAuthStore from "../Stores/Auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Footer from "../Uikits/Footer";
import Nav from "../Uikits/Nav";
import { AppLoader } from "../Uikits/Others";

export const noNeedAuth = (WrappedComponent, specificRole = false) => {
  return (props) => {
    const user = useAuthStore((state) => state.user);
    const initAuth = useAuthStore((state) => state.initAuth);
    const userLoading = useAuthStore((state) => state.userLoading);
    const userToken = useAuthStore((state) => state.userToken);
    const userIsConnected = useAuthStore((state) => state.userIsConnected);
    const getUserConnected = useAuthStore((state) => state.getUserConnected);
    // const logout = useAuthStore((state) => state.logout);
    const navigate = useNavigate();

    useEffect(() => {
      initAuth();
    }, []);
    useEffect(() => {
      if (userToken && userIsConnected) {
        getUserConnected();
      } else {
        // logout();
      }
    }, [userIsConnected, userToken]);

    if (userLoading) return <AppLoader />;
    else if (user) {
      return navigate("/dashboard");
    }

    return (
      <>
        <Nav />
        <div className="mainWithoutNav">
          <WrappedComponent {...props} />
        </div>
        <Footer />
      </>
    );
  };
};
