import React, { useState } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import { MdiPause, MdiPlay } from "../Uikits/Icons";

import WavesurferPlayer from "@wavesurfer/react";

function filterAudioVideoTestimonial(testi, audioOrVideo) {
  const testo = testi.filter(
    (item) => item.audioOrVideo && item.audioOrVideo.type == audioOrVideo
  );
  return testo;
}

function WavePlayer({ url }) {
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  return (
    <article className="waveAudioPlayer flex  ai-c">
      <span onClick={onPlayPause}>
        {isPlaying ? <MdiPause /> : <MdiPlay />}
      </span>
      <div>
        <WavesurferPlayer
          height={50}
          waveColor="#f88a4d"
          // url="https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3"
          url={url}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
    </article>
  );
}

function CliendNameAndPic({ user }) {
  return (
    <article className="clientNameAndPic">
      {" "}
      <img src={user.autorPicture.url} alt="" />
      <b>{user.autorName}</b>
    </article>
  );
}

export function TextTestimonial({ testimonials }) {
  if (testimonials.length == 0) return <p>Aucun commentaire video</p>;
  return (
    <section className="at-text">
      <h3>Témognage expressif par texte de nos étudiants</h3>
      <div className="flex f-wrap">
        {testimonials.map((item, i) => (
          <div className="t-text" key={"screen testi " + i}>
            <CliendNameAndPic user={item} />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
export function AudioTestimonial({ testimonials }) {
  if (
    testimonials.length == 0 ||
    filterAudioVideoTestimonial(testimonials, "audio").length == 0
  )
    return <p>Aucun commentaire video</p>;
  return (
    <section className="at-audio">
      <h3>Témognage expressif par audio de nos étudiants</h3>
      <div className="flex f-wrap">
        {filterAudioVideoTestimonial(testimonials, "audio").map((item, i) => (
          <div className="t-text" key={"audio testi " + i}>
            <CliendNameAndPic user={item} />

            <WavePlayer url={item.audioOrVideo.url} />
          </div>
        ))}
      </div>
    </section>
  );
}

export function VideoTestimonial({ testimonials }) {
  if (
    testimonials.length == 0 ||
    filterAudioVideoTestimonial(testimonials, "video").length == 0
  )
    return <p>Aucun commentaire video</p>;
  return (
    <section className="at-video">
      <h3>
        Témognage expressif par vidéo de nos étudiants{" "}
        {filterAudioVideoTestimonial(testimonials, "video").length}{" "}
      </h3>
      <div className="flex f-wrap">
        {filterAudioVideoTestimonial(testimonials, "video").map((item, i) => (
          <video
            controls
            key={"video testi " + i}
            // src="https://media.istockphoto.com/id/1777849954/video/paper-table-with-numbers-and-financial-indicators-closeup-4k-movie.mp4?s=mp4-640x640-is&k=20&c=FXKbTvxHkEGKtMaZIE-VvuW38ycOEe5T21WAjrcxbzc="
            src={item.audioOrVideo.url}
          />
        ))}
      </div>
    </section>
  );
}
