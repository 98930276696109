import React from "react";
import Slider from "react-slick/lib/slider";
import {
  MdiArrowLeftThin,
  MdiArrowRightThin,
  MdiCheckBold,
} from "../../Uikits/Icons";

const modules = [
  {
    module: 1,
    title: "Mindset et Création d'entreprise",
    topics: [
      "Pourquoi vous devez échouer !?",
      "L'état d'esprit de l'entrepreneur à succès",
      "Créez votre succès avec votre état d'esprit",
      "Assumez vos erreurs et vos responsabilités !",
      "Déclarez-vous facilement et gratuitement",
      "Gérez intelligemment vos bénéfices",
      "Expatriez-vous et optimisez votre entreprise",
    ],
    img: "/images/illustrations/1.webp",
  },
  {
    module: 2,
    title: "Recherche de produit",
    topics: [
      "Qu'est-ce que le business modèle de l'e-commerce",
      "Comprendre les leviers marketing et psychologiques qui pousseront vos clients à l'achat",
      "Quels sont les critères de sélection d'un produit rentable ?",
      "Stratégie avancée de recherche de produits sur TikTok",
      "Recherche de produits sur Facebook",
      "Recherche de produit sur Minea",
      "Espionnez vos concurrents et leurs réels chiffres d'affaires",
      "Lancez des produits qui fonctionnent",
      "Trouver un fournisseur de qualité",
      "Personnaliser votre packaging et votre produit pour améliorer votre image de marque et la valeur perçue de votre boutique",
    ],
    img: "/images/illustrations/2.webp",
  },
  {
    module: 3,
    title: "Création d'une boutique optimisée SHOPIFY",
    topics: [
      "Sur quels marchés se lancer !?",
      "La puissance des marchés inexploités",
      "Construisez en quelques heures votre site web professionnel sans toucher une seule ligne de code",
      "La description de produit efficace avec la méthode A.I.D.A",
      "Faire une offre marketing irrésistible",
      "Faites exploser vos taux de conversions avec des applications et des optimisations inédites",
      "Créez des visuels professionnels et très attrayants à moindre coût",
    ],
    img: "/images/illustrations/3.webp",
  },
  {
    module: 4,
    title: "Lance tes publicités",
    topics: [
      "Facebook ads: Découvrez la puissance de Facebook ads et comprenez l'écosystème de Facebook /n",
      "Facebook ads: Configurez votre business manager Facebook",
      "Facebook ads: Créez des publicités qui vous démarquent et qui vous donnent un gros avantage sur la concurrence",
      "Facebook ads: Testing et analyse de vos publicités",
      "Facebook ads: Scaling : différents types de scaling sur Facebook",
      "TikTok ads: Créatives TikTok",
      "TikTok ads: Publicités organiques avec TikTok ads avec 0 €",
    ],
    img: "/images/illustrations/6.webp",
  },
  {
    module: 5,
    title: "Branding et image de marque",
    topics: [
      "Déléguez votre Mail marketing et augmentez de 30 % votre chiffre d'affaire",
      "Automatisez vos ventes avec un agent",
      "Conseils avancés pour optimiser davantage vos boutiques",
      "Confiez votre SAV à des professionnels",
    ],
    img: "/images/illustrations/5.webp",
  },
];

function Module() {
  const settings = {
    arrows: true,
    nextArrow: (
      <span>
        <MdiArrowRightThin />{" "}
      </span>
    ),
    prevArrow: (
      <span>
        <MdiArrowLeftThin />
      </span>
    ),
    autoplay: true,
    autoplaySpeed: 8000,
  };

  return (
    <div className="homeModule">
      <Slider {...settings}>
        {modules.map((item, h) => (
          <div key={"module" + h} className="m-content flex">
            <section>
              <span>Module{" " + item.module} </span>
              <h3>{item.title} </h3>

              <ul>
                {item.topics.map((other, l) => (
                  <li key={"other" + l} className="flex ai-c">
                    <span className="flex ai-c jc-c">
                      {" "}
                      <MdiCheckBold />
                    </span>
                    <p>{other}</p>
                  </li>
                ))}
              </ul>
            </section>
            <section>
              <img src={item.img} alt="" />
            </section>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Module;
