import React from "react";
import {
  LineMdPixelfed,
  LineMdQuestionCircle,
  LineMdSpeedometerLoop,
  LineMdTextBoxMultiple,
  LineMdVolumeHigh,
} from "../../Uikits/Icons";
import Slider from "react-slick/lib/slider";
import ScrollAnimation from "react-animate-on-scroll";

const whyUsReasons = [
  {
    title: "Pourquoi cette formation ?",
    content:
      "Nous vous suivons de la création de votre boutique, pendant vos premières ventes et jusqu'à l'optimisation et l'automatisation de votre boutique.",
    icon: <LineMdQuestionCircle />,
  },
  {
    title: "Boostez vos ventes",
    content:
      "Des stratégies marketing inédites pour dominer la concurrence avec des offres irrésistibles.",
    icon: <LineMdSpeedometerLoop />,
  },
  {
    title: "Une équipe d'experts pour vous accompagner",
    content:
      "Des experts qualifiés et très expérimentés ayant déjà généré des millions d'euros pour vous accompagner pas à pas vers votre liberté financière.",
    icon: <LineMdPixelfed />,
  },
  {
    title: "Pensez stratégie",
    content:
      "Les marchés inexploités, une véritable mine d'or pour se lancer et générer rapidement ses premiers milliers d'euros.",
    icon: <LineMdTextBoxMultiple />,
  },
  {
    title: "Menez à bien vos campagnes publicitaires",
    content:
      "Bénéficiez des dernières stratégies Facebook ads et TikTok ads pour exploser vos ventes 🚀.",
    icon: <LineMdVolumeHigh />,
  },
];

const settingsLeft = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 758,
      settings: {
        slidesToShow: 1,
        fade: true,
        vertical: false,
        verticalSwiping: false,
        adaptiveHeight: true,
      },
    },
  ],
};

const settingsRight = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 2000,
};

function HomeWhyUs() {
  return (
    <div className="homeWhyUs flex f-wrap">
      <section className="hwu-left">
        <ScrollAnimation animateIn="flipInY">
          <Slider {...settingsLeft}>
            {whyUsReasons.map((item, i) => (
              <article key={"raison nb" + i}>
                <span>{item.icon}</span>
                <div>
                  <h3>{item.title} </h3>
                  <p>{item.content}</p>
                </div>
              </article>
            ))}
          </Slider>
        </ScrollAnimation>
      </section>
      <section className="hwu-right">
        <Slider {...settingsRight}>
          <div className="hwur-img">
            <img src="/images/proofs/whyUs1.gif" alt="" />{" "}
          </div>
          <div className="hwur-img">
            <img src="/images/proofs/whyUs2.gif" alt="" />{" "}
          </div>
          <div className="hwur-img">
            <img src="/images/proofs/whyUs3.webp" alt="" />{" "}
          </div>
        </Slider>
      </section>
    </div>
  );
}

export default HomeWhyUs;
