import React from "react";
import HomeBanner from "../Components/home/Banner";
import HomeResultStats from "../Components/home/ResultStats";
import HomeWhyUs from "../Components/home/WhyUs";
import HomeCoach from "../Components/home/Coach";
import HomeCustomerProof from "../Components/home/CustomerProof";
import HomeTestimonial from "../Components/home/Testimonial";
import HomePricing from "../Components/home/Pricing";
import HomeWhoIAm from "../Components/home/WhoIAm";
import Module from "../Components/home/Module";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { NewsLetter } from "../Components/others";

function Home() {
  return (
    <div className="home">
      <HomeBanner />
      <HomeResultStats />
      <HomeWhyUs />
      <HomeWhoIAm />
      <HomeCoach />
      <Module />
      <HomeCustomerProof />
      <HomeTestimonial />
      <HomePricing />
      <NewsLetter />
    </div>
  );
}

export default noNeedAuth(Home);
