import { Form, FormikProvider, useFormik } from "formik";
import FormFieldProvider from "../../Uikits/form";
import { giveEmailFields } from "../../Config/formFields/fieldArrays";
import { FormButton } from "../../Uikits/Button";
import { noNeedAuth } from "../../Hocs/NoNeedAuth";
import axiosInstance from "../../Utils/Axios";
import { errorToast, successToast } from "../../Utils/Toast";
import { LeadValidation } from "../../Config/formFields/fieldsValidations";
import { useEffect, useState } from "react";
import { SectionLoader } from "../../Uikits/Others";

function EmailNeededMiddleware({ children }) {
  const formik = useFormik({
    initialValues: { email: "", leadType: "product" },
    onSubmit: handleSubmit,
    validateOnMount: true,
    validationSchema: LeadValidation,
  });
  const { isValid, isSubmitting, setSubmitting, values } = formik;

  function handleSubmit(formvalues) {
    axiosInstance
      .post("/lead/add", formvalues)
      .then((res) => {
        if (!res.data.blocked) {
          successToast("Mail enregistre !");
        }
      })
      .catch((err) => errorToast("une erreur et survenue"))
      .finally(() => {
        setSubmitting(false);
        localStorage.setItem("productEmailNeeded", formvalues.email);
        window.location.reload();
      });
  }

  const localEmaiAlredyGaved = localStorage.getItem("productEmailNeeded");

  if (!localEmaiAlredyGaved) {
    return (
      <div className="emailNeededMiddleware">
        <h1>Veuillez renseigner votre adresse email</h1>
        <p>
          Afin de vous offrir un accès à la liste des produits et d'améliorer
          votre expérience utilisateur personnalisée, nous vous invitons à
          fournir votre adresse email.
        </p>

        <FormikProvider value={formik}>
          <Form>
            <FormFieldProvider {...giveEmailFields[0]} />
            <FormButton
              text="Soumettre"
              isValid={isValid}
              isSubmitting={isSubmitting}
            />
          </Form>
        </FormikProvider>
      </div>
    );
  }
  return (
    <CheckEmailHasBlocked email={localEmaiAlredyGaved}>
      {children}{" "}
    </CheckEmailHasBlocked>
  );
}

function CheckEmailHasBlocked({ email, children }) {
  const [loading, setLoading] = useState(true);
  const [emailBlocked, setEB] = useState(false);
  useEffect(() => {
    axiosInstance
      .post("/lead/add", { email, leadType: "product" })
      .then((res) => {
        if (res.data.blocked) {
          setEB(true);
        }
      })
      .catch((err) => errorToast("une erreur et survenue"))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <SectionLoader />;
  }

  if (emailBlocked)
    return (
      <div className="emailNeededMiddleware">
        <p>Vous ne pouvez acceder a la liste des produits</p>
      </div>
    );
  return children;
}

export default noNeedAuth(EmailNeededMiddleware);
