import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import useModalStore from "../Stores/Modal";
import axiosInstance from "../Utils/Axios";
import { allLeadPageContentByTypeUrl } from "../Utils/Queries/leadPage";
import { AddorUpdateLPParagraph } from "../Components/leadPageContents/paragraph";
import { AddorUpdateLPVideo } from "../Components/leadPageContents/video";
import { successToast } from "../Utils/Toast";
import { AddLPReview } from "../Components/leadPageContents/reviews";
import { AddorUpdateLPGallery } from "../Components/leadPageContents/gallery";
import { BackgroundButton, BorderButton } from "../Uikits/Button";
import { AddorUpdateLPButton } from "../Components/leadPageContents/button";
import { SectionLoader } from "../Uikits/Others";
import useCustomQuery from "../Hook/query";
import { AddorUpdateLPPdf } from "../Components/leadPageContents/pdf";
import { useParams } from "react-router-dom";

function ManageLeadPageContent() {
  const { leadPageTypeId, leadPageTypeLabel } = useParams();
  let url = allLeadPageContentByTypeUrl(leadPageTypeId);

  const { isLoading, error, data, refetch } = useCustomQuery(url);

  const showModal = useModalStore((state) => state.showModal);

  function upOrder(content) {
    axiosInstance
      .put("/leadPage/up-order/" + content._id + "/" + content.leadPageType)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Ordre modifier!");
      });
  }

  function downOrder(content) {
    axiosInstance
      .put("/leadPage/down-order/" + content._id + "/" + content.leadPageType)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Ordre modifier!");
      });
  }

  function deleteContent(contentId) {
    axiosInstance
      .delete(
        "/leadPage/delete/" + contentId + "?leadPageType=" + leadPageTypeId
      )
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Section supprimer !");
      });
  }
  if (isLoading) return <SectionLoader />;

  return (
    <div className="dataManager">
      <h1>Gestions de la page Lead {leadPageTypeLabel} </h1>
      <div
        className="flex f-wrap jc-se"
        style={{
          gap: "10px",
        }}
      >
        <BorderButton
          text="Nouveau Paragraphe"
          onClick={() =>
            showModal(
              <AddorUpdateLPParagraph
                refetch={refetch}
                leadPageTypeId={leadPageTypeId}
              />
            )
          }
        />
        <BorderButton
          text="Nouvelle gallerie d'image"
          onClick={() =>
            showModal(
              <AddorUpdateLPGallery
                refetch={refetch}
                leadPageTypeId={leadPageTypeId}
              />
            )
          }
        />
        <BorderButton
          text="Nouvelle video"
          onClick={() =>
            showModal(
              <AddorUpdateLPVideo
                refetch={refetch}
                leadPageTypeId={leadPageTypeId}
              />
            )
          }
        />
        <BorderButton
          text="Nouveau Pdf"
          onClick={() =>
            showModal(
              <AddorUpdateLPPdf
                refetch={refetch}
                leadPageTypeId={leadPageTypeId}
              />
            )
          }
        />
        <BorderButton
          text="Nouvelle section Temoignage"
          onClick={() =>
            showModal(
              <AddLPReview refetch={refetch} leadPageTypeId={leadPageTypeId} />
            )
          }
        />
        <BorderButton
          text="Nouveau bouton"
          onClick={() =>
            showModal(
              <AddorUpdateLPButton
                refetch={refetch}
                leadPageTypeId={leadPageTypeId}
              />
            )
          }
        />
      </div>
      {data.data.length > 0 ? (
        <section className="dm-dataList2">
          {data.data
            .sort((a, b) => a.order - b.order)
            .map((item, i) => (
              <article key={"formations nb " + i}>
                <h2>Section: {item.order} </h2>
                <br />
                {item.contentType == "paragraph" && (
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                    <BorderButton
                      text="Modifier"
                      onClick={() =>
                        showModal(
                          <AddorUpdateLPParagraph
                            refetch={refetch}
                            section={item}
                            leadPageTypeId={leadPageTypeId}
                          />
                        )
                      }
                    />
                  </div>
                )}
                {item.contentType == "gallery" && (
                  <div
                    className=""
                    style={{
                      columns: 2,
                    }}
                  >
                    {item.gallery.map((media, j) => (
                      <img
                        src={media.url}
                        alt=""
                        style={{
                          width: "100%",
                        }}
                      />
                    ))}
                  </div>
                )}
                {item.contentType == "video" && (
                  <video src={item.video.url} controls></video>
                )}

                {item.contentType == "reviews" && (
                  <p>Section Temoignage Afficher </p>
                )}

                {item.contentType == "pdf" && (
                  <embed src={item.pdf.url} type="" />
                )}

                {item.contentType == "button" && (
                  <BackgroundButton
                    text={item.buttonText}
                    isLink={true}
                    target="_blank"
                    link={item.buttonLink}
                  />
                )}

                <div className="flex f-wrap" style={{ gap: "15px" }}>
                  <button
                    className="deleteBtn"
                    onClick={() => deleteContent(item._id)}
                  >
                    Supprimer la section
                  </button>

                  {item.order > 1 && (
                    <button className="updateBtn" onClick={() => upOrder(item)}>
                      Remonter d'une position
                    </button>
                  )}

                  {item.order != data.data.length && (
                    <button
                      className="updateBtn"
                      onClick={() => downOrder(item)}
                    >
                      Descendre d'une position
                    </button>
                  )}
                </div>
              </article>
            ))}
        </section>
      ) : (
        <p>Aucune section disponible</p>
      )}
    </div>
  );
}

export default needAuth(ManageLeadPageContent);
